import React, { useContext, useEffect } from 'react'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import Inquiry from '../components/Inquiry'
import Footer from '../components/Footer'

const ContactView = () => {

    return (
        <div className='container-fluid main'>
            <div className="row">
                <Navbar />
            </div>
            <div className="row mt-3">
                <Hero />
            </div>
            <div className="row" id='contact-div'>
                <Inquiry />
            </div>
            <div className="row" id='footer mt-3'>
                <Footer />
            </div>
        </div>
    )
}

export default ContactView