import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './static/App.css';
import bootstrap from 'bootstrap';
import Main from './views/Main';
import ContactView from './views/ContactView';

function App() {

  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Main />} />
            <Route path='/contact' element={<ContactView />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
