import React from 'react'
import { Link } from 'react-router-dom'
import SectionHeading from './SectionHeading'
import family1 from '../static/images/ws-family class (1) primary.jpg';
import family2 from '../static/images/ws-family class (2).jpg';
import family3 from '../static/images/ws-family class (3).jpg';

const FamilyClasses = () => {
    return (
        <div id='family'>
            <SectionHeading title="Family Classes" />
            <p>Rick and Rebecca have been married for nearly 40 years and together have raised 3 children who are all now raising their own families. They remain involved in their children's lives and especially rejoice in time spent with their 6 grandchildren. Rick was raised in a law enforcement family and together he and Rebecca navigated the challenges of raising their own family in that environment. Together they are pleased to offer teaching and training opportunities to aid those still serving and raising families during these challenging times.</p>
            <p>Rebecca has decades of experience in public schools as a teacher, school psychologist and school counselor. She is also an award-winning author of children's books providing emotions management guidance for children and their caring adults. On many occasions Rebecca has been asked to speak to groups of educators on emotions management strategies for children. Additional information about Rebecca's work in this area can be found <Link to='https://www.emotionalwellnessproject.com/' target='_blank' id='EWPlink'>here</Link>.</p>
            <div className="family-photos">
                <div className='p-2' id='family-primary'>
                    <img src={family1} alt="" height={'500px'}/>
                </div>
                <div className='p-2' id='family-secondary'>
                    <img src={family2} alt="" height={'295px'} width='auto'/>
                    <img src={family3} alt="" height={'190px'} width='auto'/>
                </div>
            </div>
        </div>
    )
}

export default FamilyClasses