import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';


const DrawerComponent = () => {

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const navs = {
        "Services": "services-detail-div",
        "Experience": "bio-div"
    }

    const handleRedirect = (pageTarget) => {
        navigate(pageTarget);
    }

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const list = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem >
                    <ListItemButton>
                        <ListItemText primary={"Home"} onClick={() => handleRedirect('/')} />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton>
                        <ListItemText primary={"Contact"} onClick={() => handleRedirect('/contact')} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            <React.Fragment>
                <Button onClick={toggleDrawer(true)}><MenuIcon sx={{ color: "white" }} />
                </Button>
                <Drawer
                    anchor="right"
                    open={open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    {list("anchor")}
                </Drawer>
            </React.Fragment>
        </div>
    )
}

export default DrawerComponent