import React from 'react';
import SectionHeading from './SectionHeading';


const Testimonial = () => {
  return (
    <div className='testimonial-component pt-5 pb-5 mx-auto' id='testimonial'>
      <div className='inner-testimonial'>
        <SectionHeading title='TESTIMONIAL' />
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima vero sit rem dolorum quis expedita dolore voluptates quae nemo possimus a aut reiciendis eveniet, corporis numquam libero obcaecati, at nam.</p>
      </div>
    </div>
  )
}

export default Testimonial