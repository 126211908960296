import React from 'react';
import main1 from '../static/images/ws-main page (1) primary.jpg';
import main2 from '../static/images/ws-main page (2).jpg';
import main3 from '../static/images/ws-main page (3).jpg';

const MainPhotos = () => {
    return (
        <div className='p-2 mt-5 mb-5' id='main-photos-div'>
            <div id="main-primary">
                <img src={main1} alt="" className='p-1' />
            </div>
            <div id="main-secondary">
                <img src={main2} alt="" className='p-2' />
                <img src={main3} alt="" className='p-2' />
            </div>

        </div>
    )
}

export default MainPhotos