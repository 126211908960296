import React from 'react'
import Hero from '../components/Hero'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Bio from '../components/Bio'
import ServicesDetail from '../components/ServicesDetail'
import Testimonial from '../components/Testimonial'
import MainPhotos from '../components/MainPhotos'

const Main = () => {

    return (
        <div className='container-fluid main'>
            <div className="row">
                <Navbar />
            </div>
            <div className="row mt-3">
                <Hero />
            </div>
            <div className="row" id='main-photos'>
                <MainPhotos />
            </div>
            <div className="row" id='services-detail-div'>
                <ServicesDetail />
            </div>
            <div className="row" id='bio-div'>
                <Bio />
            </div>
            <div className="row">
                <Testimonial />
            </div>
            <div className="row" id='footer mt-3'>
                <Footer />
            </div>
        </div>
    )
}

export default Main