import React from 'react'
import SectionHeading from './SectionHeading'
import consulting1 from '../static/images/ws-consulting (1) primary.jpg';
import consulting2 from '../static/images/ws-consulting (2).jpg';
import consulting3 from '../static/images/ws-consulting (3).jpg';


const Consulting = () => {
    return (
        <div className='mx-auto' id='consulting'>
            <SectionHeading title="Consulting" />
            <p>Rick has extensive experience in curriculum development and policy review. His specialties include cases involving Use of Force and In Custody Death. Rick also has extensive experience with Field Training programs for first responders. Prior to retiring from active law enforcement in 2014 he managed Police Training Officer (PTO) for one of the largest sheriff's offices in the nation.</p>
            <div className="consulting-photos">
                <div className='p-2' id='consulting-primary'>
                    <img src={consulting1} alt="" height={'500px'} width={'auto'} />
                </div>
                <div className='p-2' id='consulting-secondary'>
                    <img src={consulting2} alt="" height={'295px'} width={'auto'} />
                    <img src={consulting3} alt="" height={'190px'} width={'auto'} />
                </div>
            </div>
        </div>
    )
}

export default Consulting