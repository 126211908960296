import React from 'react';
import logo from '../static/images/logo-04-final.png';

const Hero = () => {
    return (
        <div className='hero'>
            <img src={logo} className='main-logo' alt="mountain with river and star in sky" />
        </div>
    )
}

export default Hero