import React, { useState } from 'react'
import Training from './Training'
import Consulting from './Consulting'
import Witness from './Witness'
import FamilyClasses from './FamilyClasses'
import SectionHeading from './SectionHeading'

const ServicesDetail = () => {

    return (
        <div className='services-detail pt-5 pb-5'>
            <SectionHeading title='SERVICES' />
            <div className="services-detail-container">
                <div>
                    <nav className="nav services-nav nav-underline px-5">
                        <button className="nav-link service-nav" data-bs-toggle="tab" data-bs-target="#training" role='tab' aria-selected="true" aria-controls="training">Training</button>
                        <button className="nav-link service-nav" data-bs-toggle="tab" data-bs-target="#consulting" role='tab' aria-selected="true" aria-controls="consulting">Consulting</button>
                        <button className="nav-link service-nav" data-bs-toggle="tab" data-bs-target="#witness" role='tab' aria-selected="true" aria-controls="witness">Expert Witness</button>
                        <button className="nav-link service-nav" data-bs-toggle="tab" data-bs-target="#family" role='tab' aria-selected="true" aria-controls="family">Family Classes</button>
                    </nav>
                </div>
                <div className='detailed-services'>
                    <div id="training" className="collapse multi-collapse">
                        <Training />
                    </div>
                    <div id="consulting" className="collapse multi-collapse">
                        <Consulting />
                    </div>
                    <div id="witness" className="collapse multi-collapse">
                        <Witness />
                    </div>
                    <div id="family" className="collapse multi-collapse">
                        <FamilyClasses />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesDetail