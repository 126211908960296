import React from 'react';
import { Link } from 'react-router-dom';
import logoWords from '../static/images/logo-words.png';
import DrawerComponent from './DrawerComponent';

const Navbar = () => {

    return (
        <div className="container-fluid navbar fixed-top">
            <ul className="d-flex justify-content-between align-items-center nav">
                <a className="navbar-brand" href='/'>
                    <img src={logoWords} id='nav-logo' alt="mountain with river and star in sky" />
                </a>
                <li>
                    <DrawerComponent />
                </li>
            </ul>
        </div>
    )
}

export default Navbar