import React from 'react'
import SectionHeading from './SectionHeading'
import witness1 from '../static/images/ws-expert witness (1) primary.jpg';
import witness2 from '../static/images/ws-expert witness (2).jpg';
import witness3 from '../static/images/ws-expert witness (3).jpg';


const Witness = () => {
    return (
        <div className='mx-auto' id='witness'>
            <SectionHeading title="Expert Witness" />
            <p>Rick is a subject matter expert in Use of force, In Custody Death analysis and investigations and Constitutional Law enforcement. He relies on his own experiences, extensive training, and exhaustive research on these topics. Rick is skilled in policy development and review, training, legal considerations, and court testimony.</p>
            <div className="witness-photos">
                <div className='p-2' id='witness-primary'>
                    <img src={witness1} alt="" height={'500px'}/>
                </div>
                <div className='p-2' id='witness-secondary'>
                    <img src={witness2} alt="" height={'295px'} width='auto'/>
                    <img src={witness3} alt="" height={'190px'} width='auto'/>
                </div>
            </div>
        </div>
    )
}

export default Witness