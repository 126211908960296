import React from 'react';
import SectionHeading from './SectionHeading';
import uniformedOutdoorImage from '../static/images/IMG_1605 (2).JPG'

const Bio = () => {

    const certs = ['Active Bystandership for Law Enforcement (ABLE) Instructor', '21st Century Police Leadership Master Instructor', 'Blue Courage: The Heart and Mind of the Guardian Instructor', 'Master Exercise Practitioner', 'In-Custody Death Prevention Instructor']
    return (
        <div className='bio p-5'>

            <div className="bio-heading">
                <SectionHeading title="EXPERIENCE" />
            </div>
            <div className='inner-bio align-items-center mx-auto pb-5'>
                <div>

                    <div className="experience">
                        <p>Rick is a passionate and experienced trainer and consultant who brings
                            decades of military and law enforcement professional experience into
                            every opportunity to serve. Since retiring from active law enforcement
                            in 2014 he has commanded the police academy in Washington State
                            and has served as Program Manager for the development and delivery
                            of a nationally recognized leadership program. He continues to instruct
                            in leadership, Crisis Intervention Training (CIT) and wellness for law
                            enforcement and National Guard members around the country. Rick is
                            a nationally recognized expert in Leadership and the Prevention and
                            Investigation of In-Custody Death.</p>
                        <p>Rick continues to look for opportunities to serve first responders and
                            our communities by offering his services as a trainer, consultant, expert
                            witness and, along with his wife Rebecca, Law Enforcement Family
                            Classes.</p>
                    </div>
                </div>
                <div className="bio-image-div">
                    <img src={uniformedOutdoorImage} alt="rick in full uniform outdoors" id='uniformed-outdoor' />
                </div>
            </div>
            <div className="row pb-3">
                <SectionHeading title="CERTIFICATIONS" />
                <div className='d-flex col-10 mx-auto justify-content-center flex-wrap'>
                    {
                        certs.map(c => (
                            <p className='cert m-3'>{c}</p>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Bio