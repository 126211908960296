import React from 'react'
import SectionHeading from './SectionHeading'
import training1 from '../static/images/ws-training (1) primary.jpg';
import training2 from '../static/images/ws-training (2).jpg';
import training3 from '../static/images/ws-training (3).jpg';


const Training = () => {
    return (
        <div className='mx-auto' id='training'>
            <SectionHeading title="Training" />
            <p>Rick is an experienced trainer in a variety of disciplines. His specialties include Use of Force options including Firearms and Electronic Control Devices (ECD's) as well as prevention and investigations of in-custody deaths. Rick is also a certified trainer in Active Bystandership for Law Enforcement (ABLE) and has been a Crisis Intervention (CIT) staff instructor for over 20 years. He also remains active in providing training centered on law enforcement officer and National Guardsmen wellness as a staff instructor for Blue Courage, LLC.</p>
            <div className="training-photos">
                <div className='p-2' id='training-primary'>
                    <img src={training1} alt="" height={'500px'} />
                </div>
                <div className='p-2' id='training-secondary'>
                    <img src={training2} alt="" height={'295px'} width='auto'/>
                    <img src={training3} alt="" height={'190px'} width='auto'/>
                </div>
            </div>
        </div>
    )
}

export default Training