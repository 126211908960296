import React, { useRef } from 'react';
import SectionHeading from './SectionHeading';
import emailjs from '@emailjs/browser';


const Inquiry = () => {
    const form = useRef();

    const sendEmail = (e) => {
        emailjs.sendForm('service_5nwquka', 'template_xgbccof', form.current, 'zEIFIClTL7ncrPXNJ')
            .then(res => {
                < div class="alert alert-success" role="alert" >
                    Message Sent!
                </div >
            })
            .catch(err => {
                <div class="alert alert-danger" role="alert">
                    There was a problem. Please try again.
                </div>
            })
    }

    return (
        <div id='contact' className='inquiry py-5'>
            <SectionHeading title="CONTACT" />
            <div className="col-8 mx-auto">
                <form ref={form} onSubmit={sendEmail}>
                    <div className="mb-3 form-floating">
                        <input type="text" name='name' required className="form-control" />
                        <label for="inputName" className="form-label">Name</label>
                    </div>
                    <div className="mb-3 form-floating">
                        <input type="email" name='email' required className="form-control" />
                        <label for="inputEmail" className="form-label">Email</label>
                    </div>
                    <div className="mb-3 form-floating">
                        <input type="tel" name='phone' required className="form-control" />
                        <label for="inputPhone" className="form-label">Phone</label>
                    </div>
                    <div className="mb-3 form-floating">
                        <textarea name="message" required className='form-control' style={{ "height": "150px" }}></textarea>
                        <label for="message" className="form-label">Message</label>
                    </div>
                    <div className="mb-3">
                        <button type="submit" value="Send" className='btn btn-submit'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Inquiry