import React from 'react';
import logo from '../static/images/logo-04-final.png';
import { Link } from 'react-router-dom';
import SectionHeading from './SectionHeading';
import footerImg from '../static/images/ws bottom banner main page.jpg';

const Footer = () => {
    return (
        <div className='container-fluid' id='footer'>
            <div className="footer-row">
                <img src={footerImg} alt="" id='footer-img' />
            </div>
            <div className="footer-row footer-bottom row">
                <div className="footer-left col">
                    <img id='footer-logo' src={logo} alt="mountain and river with star in sky" />
                    <p className='quote'>"Servire fideliter est bene vivere"</p>
                </div>
                <div className="footer-right col">
                    <SectionHeading title="CONTACT" />
                    <p>rick@carbonriverconsulting.com</p>
                    <div className='contact-sub-div'>
                        <p>(206) 406-1372</p>
                        <Link to='https://www.linkedin.com/in/rick-bowen-b4274948/' id='linkedIn'>LinkedIn</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer